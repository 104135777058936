import { DownloadRounded } from '@mui/icons-material';
import { Alert, Box, Snackbar, Tab, Tabs, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import UploadDialog from './upload-dialog';
import UploadsActionButton from './uploads-action-button';

import preawardAdminServiceApi, { UploadType } from '@/admin/services/preaward-api';
import { trackException } from '@/application-insights';
import ImportIcon from '@/assets/icons/import.svg';
import LabelSelect from '@/components/label-select';
import preawardServiceApi from '@/forms/services/preaward-api';

const SNACKBAR_AUTO_HIDE_DURATION = 3000;

const StyledLayout = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export interface TabConfig {
  label: string;
  type: UploadType;
  stateTitle: string;
  stateButtonTitle: string;
  stateButtonSubtitle: string;
}

interface UploadManagerProps {
  tabs: TabConfig[];
}

function UploadManager({ tabs }: Readonly<UploadManagerProps>) {
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [funderId, setFunderId] = useState<string>('');
  const [funderOptions, setFunderOptions] = useState<{ value: string; label: string }[]>([]);

  const activeTabConfig = tabs[activeTab];

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const isFunderPricingSchemeUpload = activeTabConfig.type === UploadType.FunderPricingScheme;

  const showSnackbar = useCallback((message: string, isError = false) => {
    setSnackbarMessage(message);
    setError(isError ? message : null);
    setSnackbarOpen(true);
  }, []);

  const handleOnImport = useCallback(
    async (file: File) => {
      try {
        let success;
        if (isFunderPricingSchemeUpload) {
          success = await preawardAdminServiceApi.uploadFunderPricingSchemeFile(file, funderId);
        } else {
          success = await preawardAdminServiceApi.uploadFile(activeTabConfig.type, file);
        }

        if (success) {
          setOpenImportDialog(false);
          showSnackbar(`Uploaded successfully to ${activeTabConfig.label}!`);
          return true;
        }
        showSnackbar('Upload failed. Please try again.', true);
        return false;
      } catch (e) {
        showSnackbar('Failed to import file. Please try again.', true);
        return false;
      }
    },
    [activeTabConfig.label, activeTabConfig.type, showSnackbar, isFunderPricingSchemeUpload, funderId],
  );

  const handleImportDialogOpen = useCallback(() => setOpenImportDialog(true), []);
  const handleImportDialogClose = useCallback(() => setOpenImportDialog(false), []);

  const downloadTemplate = useCallback(async () => {
    try {
      const result = await preawardAdminServiceApi.getUploadTemplateFile(activeTabConfig.type);
      if (!result) {
        showSnackbar('Failed to download template. Please try again.', true);
        return;
      }
      showSnackbar('Template downloaded successfully!');
    } catch (e) {
      showSnackbar('Failed to fetch data. Please try again later.', true);
    }
  }, [activeTabConfig.type, showSnackbar]);

  useEffect(() => {
    if (isFunderPricingSchemeUpload) {
      const getFunderOptions = async () => {
        const funders = await preawardServiceApi.getFunders();
        const apiFunderOptions = funders.map((funder) => ({ value: funder.id, label: funder.name }));
        setFunderOptions(apiFunderOptions);
      };

      getFunderOptions().catch(trackException);
    }
  }, [isFunderPricingSchemeUpload]);

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue as number)}
        aria-label="Uploads tabs"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        {tabs.map((tab) => (
          <Tab key={`tab-${tab.label}`} label={tab.label} />
        ))}
      </Tabs>

      <StyledLayout>
        <Typography variant="h6" align="center" color="text.secondary" sx={{ mb: 3 }}>
          {activeTabConfig.stateTitle}
        </Typography>

        {isFunderPricingSchemeUpload && (
          <Box sx={{ width: '350px', display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
            <LabelSelect
              label="Funder Name"
              onChange={(e) => setFunderId(e)}
              menuItems={funderOptions}
              value={funderId}
            />
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3, alignItems: 'start' }}>
          <UploadsActionButton
            onClick={handleImportDialogOpen}
            icon={<img src={ImportIcon} alt="Import" style={{ width: 24, height: 24 }} />}
            title={activeTabConfig.stateButtonTitle}
            subtitle={activeTabConfig.stateButtonSubtitle}
          />

          <UploadsActionButton
            onClick={downloadTemplate}
            icon={<DownloadRounded sx={{ fontSize: 32 }} />}
            title="Download Template"
            subtitle="Download the template to import data"
          />
        </Box>
      </StyledLayout>
      <UploadDialog open={openImportDialog} onClose={handleImportDialogClose} onImport={handleOnImport} />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        onClose={() => setSnackbarOpen(false)}
        data-testid="upload-snackbar"
      >
        <Alert severity={error ? 'error' : 'success'} sx={{ width: '100%' }} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default React.memo(UploadManager);
