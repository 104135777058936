import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { FunderSummary, FunderSummaryResponse } from './types';

import { appInsights } from '@/application-insights';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 500,
    renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
  },
  {
    field: 'funderType',
    headerName: 'Funder Type',
    width: 500,
    renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
  },
];

function FunderList() {
  const navigate = useNavigate();
  const [rows, setRows] = useState<FunderSummary[]>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [total, setTotal] = useState<number>(0);

  const handleRowClick: GridEventListener<'rowClick'> = (params: GridRowParams) => {
    navigate(`/funder/${params.id}`);
  };

  useEffect(() => {
    fetch(`/api/preaward/Funder?pageNumber=${paginationModel.page + 1}&pageSize=${paginationModel.pageSize}`, {
      method: 'GET',
      headers: {
        'x-forwarded-host': window.location.hostname,
      },
    })
      .then((response) => response.json())
      .then((data: FunderSummaryResponse) => {
        setRows(data.funders);
        setTotal(data.total);
      })
      .catch((err: Error) => {
        appInsights.trackException({
          error: err,
          severityLevel: SeverityLevel.Error,
        });
      });
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <Grid container width="95%" sx={{ ml: 4, mt: 2 }}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant="h4">Funders</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Button id="createFunder" variant="contained" href="/preaward/funder">
          NEW
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
            style={{ flexDirection: 'column-reverse' }}
            columns={columns}
            rows={rows ?? []}
            pagination
            paginationMode="server"
            pageSizeOptions={[10, 15, 20, 25, 50, 100]}
            rowCount={total}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            autoHeight
            onRowClick={handleRowClick}
            loading={!rows}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default FunderList;
