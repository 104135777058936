import { GridValidRowModel } from '@mui/x-data-grid';
import axios from 'axios';
import dayjs from 'dayjs';

import { trackException } from '@/application-insights';
import { FundingDetails } from '@/forms/contexts/budget-context';
import { FunderData, GetFundersResponse } from '@/forms/types';
import {
  Categories,
  CostEntityDto,
  CostingSection,
  CostingsDto,
  ExternalDataDto,
  FacilitiesAndServicesPeriod,
  NonStaffPeriod,
  PeriodWithEffort,
  Sections,
  StaffModelDto,
  StaffPeriod,
} from '@/types';
import { costingsDateFormat } from '@/utils';

const BASE_URL = '/api/preaward';

const preawardApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

preawardApi.interceptors.response.use(
  (response) => response,
  (error: Error) => trackException(error),
);

const getCostingsPath = (sectionName: string, categoryName: string): string => {
  const sectionPaths: Record<string, string> = {
    [Sections.StaffCost + Categories.MainCategory]: '/Staff/costings', // To remove once moved to costings template using Staff & NonHrStaff categories
    [Sections.StaffCost + Categories.Staff]: '/Staff/costings',
    [Sections.StaffCost + Categories.NonHrStaff]: '/Staff/costings',
    [Sections.NonStaff + Categories.MainCategory]: '/NonStaff/costings',
    [Sections.Partners + Categories.Staff]: '/Partner/costings',
    [Sections.FacilitiesAndServices + Categories.MainCategory]: '/FacilitiesAndServices/costings',
    [Sections.Students + Categories.Studentship]: '/Students/student/costings',
    [Sections.Students + Categories.OtherStudentCosts]: '/Students/other-student-costs/costings',
  };

  return sectionPaths[sectionName + categoryName];
};

const buildStaffCostingRequest = (row: GridValidRowModel): Record<string, unknown> => ({
  fullName: row?.Name as string,
  organisationalUnit: row['Organisational Unit'] as string,
  costHeading: row['Cost Heading'] as string,
  projectRole: row['Project Role'] as string,
  grade: row.Grade as string,
  scalePoint: row.Point as string,
  ...(row['Pension Scheme'] ? { pensionScheme: row['Pension Scheme'] as string } : {}),
  ...(row['Pay Increase Date']
    ? { payIncreaseDate: dayjs(row['Pay Increase Date'] as Date).format(costingsDateFormat) }
    : {}),
  periods: (row['Time&Effort'] as StaffPeriod[])?.map((period: StaffPeriod) => ({
    from: dayjs(period.From).format(costingsDateFormat),
    to: dayjs(period.To).format(costingsDateFormat),
    effort: period.Effort,
    location: period.Location,
  })),
});

const costingsRequestBuilders: Record<string, (row: GridValidRowModel) => Record<string, unknown>> = {
  [Sections.StaffCost + Categories.MainCategory]: buildStaffCostingRequest, // To remove once moved to costings template using Staff & NonHrStaff categories
  [Sections.StaffCost + Categories.Staff]: buildStaffCostingRequest,
  [Sections.StaffCost + Categories.NonHrStaff]: buildStaffCostingRequest,
  [Sections.NonStaff + Categories.MainCategory]: (row: GridValidRowModel) => ({
    name: row?.Item as string,
    costHeading: row['Cost Heading'] as string,
    periods: (row['Time&Costs'] as NonStaffPeriod[])?.map((period: NonStaffPeriod) => ({
      from: dayjs(period.From).format(costingsDateFormat),
      to: dayjs(period.To).format(costingsDateFormat),
      cost: period.Cost,
    })),
  }),
  [Sections.FacilitiesAndServices + Categories.MainCategory]: (row: GridValidRowModel) => ({
    name: row?.Item as string,
    costHeading: row['Cost Heading'] as string,
    periods: (row['Time&Consumption'] as FacilitiesAndServicesPeriod[])?.map((period: FacilitiesAndServicesPeriod) => ({
      from: dayjs(period.From).format(costingsDateFormat),
      to: dayjs(period.To).format(costingsDateFormat),
      consumptionUnit: period['Consumption Units'],
      projectCost: period['Project Cost'],
    })),
  }),
  [Sections.Partners + Categories.Staff]: (row: GridValidRowModel) => ({
    costHeading: row['Cost Heading'] as string,
    projectCost: row['Project Cost'] as number,
    funderCost: row['Funder Cost'] as number,
    income: row.Income as number,
  }),
  [Sections.Students + Categories.Studentship]: (row: GridValidRowModel) => ({
    scholarshipType: row['Scholarship Type'] as string,
    costHeading: row['Cost Heading'] as string,
    periods: (row['Time&Effort'] as PeriodWithEffort[])?.map((period: PeriodWithEffort) => ({
      from: dayjs(period.From).format(costingsDateFormat),
      to: dayjs(period.To).format(costingsDateFormat),
      effort: period.Effort,
    })),
  }),
  [Sections.Students + Categories.OtherStudentCosts]: (row: GridValidRowModel) => ({
    name: row?.Item as string,
    costHeading: row['Cost Heading'] as string,
    periods: (row['Time&Costs'] as NonStaffPeriod[])?.map((period: NonStaffPeriod) => ({
      from: dayjs(period.From).format(costingsDateFormat),
      to: dayjs(period.To).format(costingsDateFormat),
      cost: period.Cost,
    })),
  }),
};

const downloadFile = (data: Blob, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

const preawardServiceApi = {
  saveCostingsInForm: async (
    formId: string,
    updatedPageId: string,
    userProjectFormId: string,
    sections: CostingSection[],
    funderDetails: FundingDetails,
  ) => {
    const requestBody: {
      formId: string;
      pageId: string;
      userProjectFormId: string;
      sections: CostingSection[];
      pricingSettings: { funderId: string; pricingSchemeName?: string } | null;
    } = {
      formId,
      pageId: updatedPageId,
      userProjectFormId,
      sections,
      pricingSettings: null,
    };

    if (funderDetails.funderId && funderDetails.pricingSchemeName) {
      requestBody.pricingSettings = {
        funderId: funderDetails.funderId,
        pricingSchemeName: funderDetails.pricingSchemeName,
      };
    }

    const response = await preawardApi.post('/costings', requestBody);
    return response.status === 204;
  },

  getCostings: async (formId: string, pageId: string, userProjectFormId: string): Promise<CostingsDto> => {
    const response = await preawardApi.get(
      `/costings?formId=${formId}&pageId=${pageId}&userProjectFormId=${userProjectFormId}`,
    );
    return response.data as CostingsDto;
  },

  getSubmission: async (formId: string, pageId: string, submissionId: string): Promise<CostingsDto> => {
    const response = await preawardApi.get(
      `/submission?formId=${formId}&pageId=${pageId}&submissionId=${submissionId}`,
    );
    return response.data as CostingsDto;
  },

  getFunders: async (pageSize = 10, pageNumber = 1): Promise<FunderData[]> => {
    const path = `/Funder?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const response = await preawardApi.get(path);
    const data = response.data as GetFundersResponse;
    return data.funders;
  },

  getFunderById: async (funderId: string): Promise<FunderData> => {
    const path = `/Funder/${funderId}`;
    const response = await preawardApi.get(path);
    return response.data as FunderData;
  },

  getCostingsTable: async (
    sectionName: string,
    categoryName: string,
    parentRow: GridValidRowModel,
    fundingDetails: FundingDetails,
  ): Promise<CostEntityDto> => {
    const path = getCostingsPath(sectionName, categoryName);
    const requestBody = costingsRequestBuilders[sectionName + categoryName](parentRow);
    requestBody.funderId = fundingDetails.funderId;
    requestBody.pricingSchemeName = fundingDetails.pricingSchemeName;
    const response = await preawardApi.post(path, requestBody);
    return response.data as CostEntityDto;
  },

  getSummary: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    fundingDetails: FundingDetails,
  ): Promise<CostEntityDto> => {
    let path = `/Summary?formId=${formId}&pageId=${pageId}&userProjectFormId=${userProjectFormId}`;
    if (fundingDetails.funderId) {
      path = path.concat(`&funderId=${fundingDetails.funderId}`);
    }
    if (fundingDetails.pricingSchemeName) {
      path = path.concat(`&pricingSchemeName=${fundingDetails.pricingSchemeName}`);
    }
    const response = await preawardApi.get(path);
    return response.data as CostEntityDto;
  },

  getExternalData: async (path: string): Promise<ExternalDataDto> => {
    const response = await preawardApi.get(path);
    return response.data as ExternalDataDto;
  },

  getStaffByNameFilter: async (staffPath: string, name: string): Promise<StaffModelDto> => {
    const response = await preawardApi.get(`${staffPath}?name=${name}`);
    return response.data as StaffModelDto;
  },

  getBudgetExport: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    funderId?: string,
  ): Promise<boolean> => {
    try {
      const response = await preawardApi.get('/BudgetExports', {
        params: { formId, pageId, userProjectFormId, funderId },
        responseType: 'blob',
      });
      downloadFile(response.data as Blob, `budget_export_${userProjectFormId}.csv`);
      return true;
    } catch (e) {
      return false;
    }
  },

  getYearlyBudgetExport: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    funderId?: string,
  ): Promise<boolean> => {
    try {
      const response = await preawardApi.get('/BudgetExports/Yearly', {
        params: { formId, pageId, userProjectFormId, funderId },
        responseType: 'blob',
      });
      downloadFile(response.data as Blob, `yearly_budget_export_${userProjectFormId}.csv`);
      return true;
    } catch (e) {
      return false;
    }
  },
};

export default preawardServiceApi;
