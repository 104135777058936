export type Data = Record<string, unknown>;

export function removeEmptyFields<T extends Data>(obj: T): T {
  const newObj = { ...obj };
  Object.entries(newObj).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      const nestedObj = removeEmptyFields(value as Data);
      if (!Object.keys(nestedObj).length) {
        delete newObj[key as keyof typeof newObj];
      } else {
        newObj[key as keyof typeof newObj] = nestedObj as unknown as T[keyof T];
      }
    } else if (value === null || value === undefined || (typeof value === 'string' && value.length === 0)) {
      if (Array.isArray(newObj)) newObj.splice(Number(key), 1);
      else delete newObj[key as keyof typeof newObj];
    }
  });
  return newObj;
}

export function assignEmptyStringToNullFields<T extends Record<string, unknown>>(obj: T): T {
  const newObj = { ...obj };
  Object.entries(newObj).forEach(([key, value]) => {
    if (value === null) {
      newObj[key as keyof typeof newObj] = '' as unknown as T[keyof T];
    } else if (value && typeof value === 'object' && !Array.isArray(value)) {
      newObj[key as keyof typeof newObj] = assignEmptyStringToNullFields(
        value as Record<string, unknown>,
      ) as unknown as T[keyof T];
    }
  });
  return newObj;
}

export function lowerFirstLetter(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const dateFormat = 'DD/MM/YYYY';
export const costingsDateFormat = 'YYYY-MM-DD';

export const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
