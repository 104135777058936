import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import Tracer from './tracer';

const otlpUrl = process.env.REACT_APP_DASHBOARD_OTLP_HTTP_ENDPOINT_URL ?? '';
if (otlpUrl) {
  Tracer(otlpUrl);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App />
  </BrowserRouter>,
);
