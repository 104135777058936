import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import MaterialCombobox, { Option } from '@/components/material-combobox';
import { useFacilitiesAndServicesContext } from '@/forms/contexts/facilities-services-context';
import preawardServiceApi from '@/forms/services/preaward-api';
import { DropdownColumnDefinition, ExternalColumnDefinition, ExternalData, FacilitiesAndServicesData } from '@/types';
import { lowerFirstLetter } from '@/utils';

function DropdownRenderCell(cellValues: GridRenderCellParams) {
  return cellValues.value ? (
    <Grid container>{cellValues.value}</Grid>
  ) : (
    <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
      <Grid item xs={10}>
        Select
      </Grid>
      <Grid item xs={2} sx={{ paddingTop: 0.6 }}>
        <ArrowDropDownIcon color="primary" />
      </Grid>
    </Grid>
  );
}

function EditableCombobox({ options, cellValues }: Readonly<{ options: Option[]; cellValues: GridRenderCellParams }>) {
  return (
    <MaterialCombobox
      id={cellValues.field}
      options={options}
      onChange={async (option) => {
        await cellValues.api.setEditCellValue({
          id: cellValues.id,
          field: cellValues.field,
          value: option?.value,
        });
        if (option && cellValues.api.getCellMode(cellValues.id, cellValues.field) === 'edit') {
          cellValues.api.stopCellEditMode({ id: cellValues.id, field: cellValues.field });
        }
      }}
      initialValue={{
        label: cellValues.value as string,
        value: cellValues.value as string,
      }}
      autoOpen
    />
  );
}

function createColumnDefinition(options: Option[], baseColDef: GridColDef): GridSingleSelectColDef {
  return {
    ...baseColDef,
    type: 'singleSelect',
    valueOptions: options.map((option) => option.value),
    renderCell: DropdownRenderCell,
    renderEditCell: (cellValues: GridRenderCellParams) => (
      <EditableCombobox options={options} cellValues={cellValues} />
    ),
  };
}

export function addDropdownColumnDefinition(ddColumn: DropdownColumnDefinition, colDefs: GridColDef) {
  const options = ddColumn.options.map((option) => ({
    label: option.name,
    value: option.value,
  }));

  return createColumnDefinition(options, colDefs);
}

export function AddExternalColumnDefinition(colDef: GridColDef, column: ExternalColumnDefinition) {
  const { setFacilitiesAndServicesData } = useFacilitiesAndServicesContext();
  const externalColumnDefinition = column;
  const { path } = externalColumnDefinition;
  const [options, setOptions] = useState<Option[]>([]);
  const propertyName = lowerFirstLetter(externalColumnDefinition.propertyName) as keyof ExternalData;

  useEffect(() => {
    const getExternalData = async () => {
      const { data } = await preawardServiceApi.getExternalData(path);

      const valuesUnsorted = Array.from(
        new Set(data.map((item: ExternalData) => item[propertyName] as string | number)),
      );

      const compareNumber = (a: number, b: number) => a - b;
      const compareString = (a: string, b: string) => a.localeCompare(b);
      let compareFn: typeof compareNumber | typeof compareString;
      if (valuesUnsorted.every((v) => _.isNumber(v))) {
        compareFn = compareNumber;
      } else if (valuesUnsorted.every((v) => _.isString(v))) {
        compareFn = compareString;
      } else {
        throw new Error(`Unexpected mixture of string and number type values in external data source ${path}`);
      }

      const newOptions = valuesUnsorted
        .sort(compareFn as (a: string | number, b: string | number) => number)
        .map((value: string | number) => ({
          label: value,
          value,
        }));
      setOptions(newOptions);

      if (path === '/FacilitiesAndServices') {
        setFacilitiesAndServicesData(data as FacilitiesAndServicesData[]);
      }
    };

    getExternalData().catch(() => {});
  }, [path, propertyName, setFacilitiesAndServicesData]);

  return createColumnDefinition(options, colDef);
}
